import clsx from 'clsx';
import React from 'react';
import x  from '../assets/images/x-social.svg'
import fb from '../assets/images/fb-social.svg'
import ig from '../assets/images/ig-social.svg'
import { useLS } from '../contexts/LsContext.js';
import { useLayout } from '../contexts/layoutContext.js';
import { useItinerary } from '../contexts/itineraryContext.js';
import { useWizard } from '../contexts/wizardContext.js';

const Menu = ({showMenu, toggleMenu}) => {

    const LSData = useLS() || {};
    const {removeAllDataFromLS} = LSData

    const layoutData = useLayout() || {};
    const {setUserLocation, userLocation} = layoutData

    const itineraryData = useItinerary() || {};
    const {scheduledDays, optionsItems} = itineraryData

    const wizardData = useWizard() || {};
    const {wizardItems} = wizardData

    const createNewTrip = async () => {
        await removeAllDataFromLS();
        setUserLocation(1);
        toggleMenu()
    }

    return (
        <nav className={clsx(showMenu ? 'left-0' : 'left-[100%]', 'Menu fixed top-[64px] w-full h-full bg-light-grey z-50 transition-all')}>
            <ul className='text-right p-6'>
                {/* <li className='text-lg mb-6'>Homepage</li> */}
                
                {(scheduledDays.length>0 && userLocation !== 4) && (
                    <button onClick={() => {
                        setUserLocation(4)
                        toggleMenu()
                    }} className='block ml-auto'>
                        <li className='text-lg mb-6'>Dettagli viaggio</li>
                    </button>
                )}
                
                {(optionsItems.length>0 || scheduledDays.length>0 || wizardItems.length>0) && (
                    <li className='text-lg mb-6'>
                        <button onClick={createNewTrip} className='block ml-auto'>
                            Nuovo viaggio
                        </button>
                    </li>
                )}
                <li className='text-lg mb-6'>FAQ</li>
                {/* <li className='text-lg mb-6'>Visit Campobasso</li> */}
            </ul>
            <ul className='flex justify-end p-6'>
                <li className='ml-6'>
                    <img className='h-[24px]' src={x} />
                </li>
                <li className='ml-6'>
                    <img className='h-[24px]' src={fb} />
                </li>
                <li className='ml-6'>
                    <img className='h-[24px]' src={ig} />
                </li>
            </ul>
        </nav>
    )
}

export default Menu;