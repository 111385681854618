export const recapData =  {
    text: 'Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione.      È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri trasferibili “Letraset”, che contenevano passaggi del Lorem Ipsum, e più recentemente da software di impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum.', 
    amenities: [
        {
            type: 'trekking',
            name: 'Museo Filippo 1',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'cultural',
            name: 'Museo Filippo 2',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 
        },
        {
            type: 'cultural',
            name: 'Museo Filippo 3',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'trekking',
            name: 'Museo Filippo 4',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'food',
            name: 'Museo Filippo 5',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'food',
            name: 'Museo Filippo 6',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'poi',
            name: 'Museo Filippo 7',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'food',
            name: 'Museo Filippo 8',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'trekking',
            name: 'Museo Filippo 9',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'poi',
            name: 'Museo Filippo 10',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'trekking',
            name: 'Museo Filippo 11',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        
        {
            type: 'hotels',
            name: 'Museo Filippo 12',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'poi',
            name: 'Museo Filippo 13',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'poi',
            name: 'Museo Filippo 14',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
        {
            type: 'hotels',
            name: 'Museo Filippo 15',
            location: 'Campobasso',
            googlePlaceId: 'kfjdshfd7ify6digdi' 

        },
    ]
}








export const days = [
    {
        date : '2024-10-12',
        activities: [
            {
                type: 'trekking',
                name: 'Museo Filippo',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 324234321,
            },
            {
                type: 'transfer',
                name: 'Museo POI 2',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 7652,
            },
            {
                type: 'poi',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 2342343,
            },
            {
                type: 'transfer',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 4434,
            },
        ] 
    },
    {
        date : '2024-10-13',
        activities: [
            {
                type: 'poi',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 243243241, 
            },
            {
                type: 'trekking',
                name: 'Museo Filippo',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h' ,
                id: 4343424141442,
            },
            {
                type: 'transfer',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h' ,
                id: 1113,
            },
            {
                type: 'transfer',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h' ,
                id: 4789,
            },
        ] 
    },
    {
        date : '2024-10-14',
        activities: [
            {
                type: 'food',
                name: 'Food POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 53456431,
            },
            {
                type: 'trekking',
                name: 'Museo Filippo',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 243,
            },
            {
                type: 'transfer',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 3222222,
            },
            {
                type: 'transfer',
                name: 'Museo POI',
                location: 'Campobasso',
                googlePlaceId: 'kfjdshfd7ify6digdi',
                duration: '2h',
                id: 477777,
            },
        ] 
    },
    
]




export const chatMock = [
    {
        agent: 'bot', 
        text: 'Cosa posso fare per te?',
        options: [],
    },
    {
        agent: 'user', 
        text: 'Puoi trovarmi delle alternative alla trattoria "Ristorante L\'Antica Fonte"?',
        options: [],
    },
    {
        agent: 'bot', 
        text: 'Certo! Ti propongo delle alternative sempre di cucina tradizionale. Fammi sapere quello che ti interessa di più',
        options: [
            {
                type: 'food',
                name: 'Ristorante Il Potestà',
                location: 'Vico Francesco Persichillo, 1, Campobasso',
                placeId: 'ChIJl3_40CwLOhMRtJkEGrtTY8g',
                duration: '2h',
                id: 53456431,
            },
            {
                type: 'food',
                name: 'Osteria28',
                location: 'Via Francesco De Attellis, 9, Campobasso',
                placeId: 'ChIJ3afur9J0OhMRjXxj04o50SM',
                duration: '2h',
                id: 53456431,
            },
            {
                type: 'food',
                name: 'Monticelli, sapere e sapori',
                location: 'Via Sant\'Antonio Abate, 33, 86100 Campobasso',
                placeId: 'ChIJ05_R5dJ0OhMR4SgS0M_IuQk',
                duration: '2h',
                id: 53456431,
            },
        ],
    },
    {
        agent: 'user', 
        text: ' Mi piacerebbe andare al Monticelli, Sapere e Sapori. Me lo puoi sostituire con L\'Antica Fonte?',
        options: [],
    },
    {
        agent: 'bot', 
        text: 'Certo! Dammi un secondo... Ecco fatto!',
        options: [],
    },
    {
        agent: 'user', 
        text: 'Perfetto, grazie zIA!',
        options: [],
    },
    {
        agent: 'bot', 
        text: 'Figurati! Sono sempre qui se mi cerchi!',
    },
]

export const googlePlaceDetails = {
    "html_attributions" : [],
    "result" : 
    {
       "current_opening_hours" : 
       {
          "open_now" : true,
          "periods" : 
          [
             {
                "close" : 
                {
                   "date" : "2024-04-09",
                   "day" : 2,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-08",
                   "day" : 1,
                   "time" : "0700"
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-10",
                   "day" : 3,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-09",
                   "day" : 2,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-10",
                   "day" : 3,
                   "time" : "2359",
                   "truncated" : true
                },
                "open" : 
                {
                   "date" : "2024-04-10",
                   "day" : 3,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-04",
                   "day" : 4,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-04",
                   "day" : 4,
                   "time" : "0000",
                   "truncated" : true
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-05",
                   "day" : 5,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-04",
                   "day" : 4,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-06",
                   "day" : 6,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-05",
                   "day" : 5,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "date" : "2024-04-07",
                   "day" : 0,
                   "time" : "0200"
                },
                "open" : 
                {
                   "date" : "2024-04-06",
                   "day" : 6,
                   "time" : "1700"
                }
             }
          ],
          "weekday_text" : 
          [
             "Monday: 7:00 AM – 2:00 AM",
             "Tuesday: 11:00 AM – 2:00 AM",
             "Wednesday: 11:00 AM – 2:00 AM",
             "Thursday: 11:00 AM – 2:00 AM",
             "Friday: 11:00 AM – 2:00 AM",
             "Saturday: 5:00 PM – 2:00 AM",
             "Sunday: Closed"
          ]
       },
       "editorial_summary" : 
       {
          "language" : "en",
          "overview" : "Moody, informal bar serving cocktails & light bites amid eclectic furnishings & offbeat decor."
       },
       "formatted_address" : "Via Moretto da Brescia, 36, 20133 Milano MI, Italy",
       "formatted_phone_number" : "02 7000 9543",
       "name" : "Union Club",
       "opening_hours" : 
       {
          "open_now" : true,
          "periods" : 
          [
             {
                "close" : 
                {
                   "day" : 2,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 1,
                   "time" : "0700"
                }
             },
             {
                "close" : 
                {
                   "day" : 3,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 2,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "day" : 4,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 3,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "day" : 5,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 4,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "day" : 6,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 5,
                   "time" : "1100"
                }
             },
             {
                "close" : 
                {
                   "day" : 0,
                   "time" : "0200"
                },
                "open" : 
                {
                   "day" : 6,
                   "time" : "1700"
                }
             }
          ],
          "weekday_text" : 
          [
             "Monday: 7:00 AM – 2:00 AM",
             "Tuesday: 11:00 AM – 2:00 AM",
             "Wednesday: 11:00 AM – 2:00 AM",
             "Thursday: 11:00 AM – 2:00 AM",
             "Friday: 11:00 AM – 2:00 AM",
             "Saturday: 5:00 PM – 2:00 AM",
             "Sunday: Closed"
          ]
       },
       "photos" : 
       [
          {
             "height" : 681,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/107935504511506058792\"\u003eUnion Club\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJbJrNGujgs-A-VGKqdPXsdN8Uictu73yfb4rJiwo1NX7aw5EKRNOdm2A0YFr20RbrZd3HoLxWMS-L-rKsIqaEOa4Vlq7OiUma_c3HpauKcjhP6Y9nCeYNmV7cSmO4ic22L7OExgv_u7jkpd_9l38JV6bKvbf3BX2fV1BhwahZI9cRza",
             "width" : 1024
          },
          {
             "height" : 9000,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/102367894268835696363\"\u003eAmy N\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJbSNGyWSOmG_ih2NNjNkL2U4w5L2MOBY_qRtMVWsMHZ-CvnyCpozcV5oSzYIVRGdPDYTlbwsL5sPEhvhC32B15wo4gUkxp9XscZry4Pb3FgsZ2xZlY6RSiVypdbZOHIhWHexap5iP_aGfS7j03qW_5MYGQ4qzv_E17TgsfhXEY3fq0O",
             "width" : 12000
          },
          {
             "height" : 6936,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/107019126562372785444\"\u003eHarisa Tulumovic\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJYVfFk3O2f2tEy1uUjpF19TLdRp57lemCjB8JUyVVGUIx4VJky_Mw04aDkKxfdZFbg5iv-6sNtwkYKk1DTwudt8vZZF-qONEpVdIa9y-2XLVqcW_DIjFm_3UM6kNrI-q8sX3lepDhd8LSDCEwGd7Z8XgLREMpxZbHxp3j1hKSVdjS4L",
             "width" : 9248
          },
          {
             "height" : 4000,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/116194681978144574908\"\u003eTorbjörn Rissve\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJYOvzctSF9-yYP9qselgIoFftnDJZxYGjmR5rIRS3LH4cPNCKtSLpcrNJuNLCgbchAjIOnMh4Dy3axxNueVaDBurvdE4tdrfIrXGtGnDeGLZZIj3pHTnnzjoP-UoVh2NK07pON6fXPs9WlsJpK_22MbFIrfvv4d6TyEhiWB99lyAqRA",
             "width" : 3000
          },
          {
             "height" : 3480,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/104087912663414501485\"\u003eLeonardo Ganazzali\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJa-6IBpp3t1cKK6ayeP3s-oO6BFNjdxEDWZyQLz1C-KttZQhQRhPZ-D3bYS1cfLsNyedHtZaRASLJjcKrJm8nTyL1XSDX5HLRELYxXOzH8MMECmnxwK-reB24y_Un6l-06hjfMvgk3dBueBkQ6C8V_e0-WfjGUh2YHI1DqQh1Hicqlg",
             "width" : 4640
          },
          {
             "height" : 3472,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/101111542971253013896\"\u003eElena Scozzari\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJb3ED-AdoX9nLH_sw0H-qIdIK0xZXfiuqKf1MmsjLw6HAuI-fhvGb84chz6CZbEGn8C041f4rUroSFGSIAR4d8f28hGp2Luq7sL7C0_tNrtdHYl7wm18LeJN8A1C2mtpi8TkA_xaqO9dVGRrefkLWftdO2Z-YpnaQHpOWjbCHp8E2ng",
             "width" : 4624
          },
          {
             "height" : 4032,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/111574001401472907976\"\u003eLorenzo Jigoreanu\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJY5ojlFTB6KKbh25tCuHvH2pdLWUK5CFIwEJWzbt8-i5VmVhDbtEglbm2H747cQ26mYNDAbaLpI4Hw6orL43ay7cBbUdoSyY9zGKm0AlzA2zpfNKaCZp-MEKAmX08R494qDIospVtgq_JjF_oV5YjDcXOxW_1c7G4D0CRU_io08dXrl",
             "width" : 3024
          },
          {
             "height" : 3464,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/100087591887830120098\"\u003eAlessandro Martella\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJZ3NfI3FkQn2OJkWpXx0s2aktWbhdf81Tpc6CKhRI5RF3qxBDQttFga6frl8FahAoIYjlxPRcArIb3gqpRVhy_O0yGxt7GiejiVnDDQQuzBcLMp6ukfediWxnk_MrkmPkLd6WRcJJtiaTtmmYIcGZkgXeVUHCiamp36vl6SU1E13m_r",
             "width" : 4618
          },
          {
             "height" : 4032,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/100931988084232510707\"\u003eMagdalena Krysa\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJY9bQxGVoSZj2ZFIK0L4Rl_N7VW3Q2pqiBIXbFnz7dHjn--j70d7MkmYr48MyoXF5QuTfVPxq5S7T7ULflA7FePEtIZjMQBSi0vNuJXUILt3FYzA1wKmHxWujO2WZpVrq86GmKGBT20dOO15-ifTiFrplsDczav5Uw7QmENVoxXptKL",
             "width" : 2268
          },
          {
             "height" : 4160,
             "html_attributions" : 
             [
                "\u003ca href=\"https://maps.google.com/maps/contrib/106787927958348425236\"\u003eAdriano The Bank\u003c/a\u003e"
             ],
             "photo_reference" : "ATplDJbvPbrqGYUZnr_A1uRkTEZpB3yIe7YSBumwU0229zHTQeMRa-krJ9Mkev70d1E0ry-XfDrJr1fXQfTVWnt_AnQjyd4KYOPHQGnc2J_ixYaqWWAdDhLRlUpuEsB773amAa7PadXLw-yf0PH7nOdqNtTmzvk8khb9kdAptqE4sMK8VhbF",
             "width" : 3120
          }
       ],
       "place_id" : "ChIJpao4FYvGhkcRWzMv5JyJF2I",
       "rating" : 4.4,
       "types" : 
       [
          "bar",
          "point_of_interest",
          "establishment"
       ],
       "url" : "https://maps.google.com/?cid=7068269447117484891",
       "website" : "https://www.facebook.com/UnionClub-41926831552/"
    },
    "status" : "OK"
 }


 export const response = {
  // OK
   placeID: {
      "current_opening_hours": {
        "open_now": true,
        "periods": [
          {
            "close": {
              "date": "2024-04-15",
              "day": 1,
              "time": "2359",
              "truncated": true
            },
            "open": {
              "date": "2024-04-09",
              "day": 2,
              "time": "0000",
              "truncated": true
            }
          }
        ],
        "weekday_text": [
          "lunedì: Aperto 24 ore su 24",
          "martedì: Aperto 24 ore su 24",
          "mercoledì: Aperto 24 ore su 24",
          "giovedì: Aperto 24 ore su 24",
          "venerdì: Aperto 24 ore su 24",
          "sabato: Aperto 24 ore su 24",
          "domenica: Aperto 24 ore su 24"
        ]
      },
      "editorial_summary": {
        "language": "it",
        "overview": "Parco regionale montuoso incontaminato con tre laghi e foreste, habitat naturale di aquile reali e lupi."
      },
      "formatted_address": "Provincia di Caserta, Italia",
      "formatted_phone_number": "0823 786942",
      "name": "Parco Regionale del Matese",
      "opening_hours": {
        "open_now": true,
        "periods": [
          {
            "open": {
              "day": 0,
              "time": "0000"
            }
          }
        ],
        "weekday_text": [
          "lunedì: Aperto 24 ore su 24",
          "martedì: Aperto 24 ore su 24",
          "mercoledì: Aperto 24 ore su 24",
          "giovedì: Aperto 24 ore su 24",
          "venerdì: Aperto 24 ore su 24",
          "sabato: Aperto 24 ore su 24",
          "domenica: Aperto 24 ore su 24"
        ]
      },
      "photos": [
        {
          "height": 2736,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/104589697320213153464\">Antonio Argentiere</a>"
          ],
          "photo_reference": "ATplDJZlNruCu7kk7dF4LFfjTe7bL8ny43wZY8KTlCAO-EeYroSuGdF6ipJoUiPbIvD1Z_7Lb6ZTYWnkZ0IhNnTKC6li-ey0dz6RUCcXC7hNeXq5LnOOgsrKqFWug2WCkk4_PGTHlpcBzKrPaTRqFnZoWNKXGqHckveZ3GxJwbkjR9BOUSyF",
          "width": 3648
        },
        {
          "height": 2160,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/113957340270684000648\">erasmo di nucci</a>"
          ],
          "photo_reference": "ATplDJbq31iXR1Ixr6dscTBl4_jMsMZ8ifEm7j9Ff5HNrht4jHwENpiSadek5hAZSBVEQmSZ1TL1_3bnpHC_4D_uGXwpUN-D-2oL4v0Zi2RhctvWb4AgAkc0YhCgR3ZDSkMuC-wTZhsKF9rBsr2r-eZOQfu3NOVC-TP4cOIItukWJwy8loph",
          "width": 3840
        },
        {
          "height": 2250,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/104117176410976913735\">Lorenzo Colzi</a>"
          ],
          "photo_reference": "ATplDJbGvtsxdocIcOOGrBgCfuotfWj2y4ohYhAz5vlUzoUksFQV8xZPWeXndGhwoZbKvcTfkMQBbvTcTjegfCpSkNZeprl5nIi3bYgy4s4yXRisBD7mD3OVe1VlGmfw4LFswV4oCHMZe2FjmPC02zc8qz3bVEwJbuHgZj-EjA8FXw-8aavF",
          "width": 4000
        },
        {
          "height": 4623,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/108584250405323392880\">Katia Dongiovanni</a>"
          ],
          "photo_reference": "ATplDJaPoodRn11hT-y9wrQDOOuUGuRsenkRcBNClwBHq_CaKv2VD7ihMNWwmL39haRljf_KXojr2eHbtfPCgI_RwQ2LKKTXHjyjTi79wNM9k4lm-i3hMnbMFjYtUWHKgW_jDMQ8xg2CwQO79Hxm-6aWnZ8to0ezXhSpBj2jw09pjEUz8zuP",
          "width": 3461
        },
        {
          "height": 2674,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/103581986166907775934\">pasquale orlando</a>"
          ],
          "photo_reference": "ATplDJZ3fJnRrO_Ocn3qY34sN9W-jVL1Ryuz7w-_TpuHd7gD0c7pWWd8j9sAGK5U6OFigtgblROts6FrfdfmAo4vnbVwSuPIGzR_EEyWGU546rhy6F3qIsw1FeSKhBD8lxtJuFEXFYLXHZTCgFQAIpMFnbLv3R3DAvpwB3hK8lrYVsDvvjgK",
          "width": 3024
        },
        {
          "height": 4248,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/116971368902195905188\">Palumbo Antonio</a>"
          ],
          "photo_reference": "ATplDJZq_Ixk-eZH1rpA7JL8SnIY8jBXJOkJocr_tz9kk2bjD02fJkkqYU447mphEzbVCD-ZN_mMZbhS-vYPoiIVAdDbxHI_6Q6UatYHHcpfEbjfCnw4LcqevnvDGERCC1-7pIO-ZOIySiDWCr2wO7YojcK6idpvewIyIeNbi6dr-GtdMiT3",
          "width": 5664
        },
        {
          "height": 3286,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/105557388648742126969\">Luca Cirillo</a>"
          ],
          "photo_reference": "ATplDJaH3FuQELL_MYne6xvsiVtB0SRQXLIVjHFIiI4NfcCJTPaex_0XK6VXjoxMHowCbXxJMpNmxs790ktWFm8Ki1aIPexAvX1Ivdo0vTIFaqlhRxfXNMuBmgYfFDnD9JsQ19lLnR0oWtngUFQ7AS-FxcvV_haJLzERm0kbIzFrZOQlR-Qm",
          "width": 2432
        },
        {
          "height": 4032,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/106405782896228422581\">Jens Grunert</a>"
          ],
          "photo_reference": "ATplDJbSuWN0qIykEweVCRICVz2ATDeL8k9zuu0IOJiKb8Yait6WoUbu8weoXXmMcWN0E3Z8MEuMZXgheqA5D066yNgfPHIMCWEe05m4I_MkAlXncLtaKQb832fTvDnj49rCq1wYIHX99kTnJePkGLsKPSAvphL7BMeCx3uZpS8UVTecQ27o",
          "width": 3024
        },
        {
          "height": 3472,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/114147006767892064558\">Davide C</a>"
          ],
          "photo_reference": "ATplDJazhovEmt_-Km4-YGeeg1j7X5kVisc_YoZPrfVEWjYcPhTfiDh4bnuS3Mzg1yDksX0c4lF7nYISpj1ANZpB8hi_xGTSK1AYgL3JCT4ajgSD-bXJh98JPU-g3RMXzqtCIayXT7EKQDH_Eifl32wHeNvizeyUq1-6MSeYLVNysHKfSAqr",
          "width": 4640
        },
        {
          "height": 3024,
          "html_attributions": [
            "<a href=\"https://maps.google.com/maps/contrib/106405782896228422581\">Jens Grunert</a>"
          ],
          "photo_reference": "ATplDJbe1SzFRgkDLwG5i0jFzLqLyISWCwBtmmCRLtvygMwq0EsU4f146cfZqnQxwd0RHBwSNwI5uEmcOzUGZsrR-954YKn3n2NAszzMwRsW_Rv1Qv0fpDDCpynWyYZOrZolPI4MghwvxRcCHxqnZkHNdzga-E8Ek9SibZPJVONJc0VWGflT",
          "width": 4032
        }
      ],
      "rating": 4.5,
      "types": [
        "tourist_attraction",
        "park",
        "zoo",
        "point_of_interest",
        "establishment"
      ],
      "url": "https://maps.google.com/?cid=1083644715368919184",
      "website": "http://www.parcoregionaledelmatese.it/site/",
      "photo_urls": [

        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJZlNruCu7kk7dF4LFfjTe7bL8ny43wZY8KTlCAO-EeYroSuGdF6ipJoUiPbIvD1Z_7Lb6ZTYWnkZ0IhNnTKC6li-ey0dz6RUCcXC7hNeXq5LnOOgsrKqFWug2WCkk4_PGTHlpcBzKrPaTRqFnZoWNKXGqHckveZ3GxJwbkjR9BOUSyF&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJbq31iXR1Ixr6dscTBl4_jMsMZ8ifEm7j9Ff5HNrht4jHwENpiSadek5hAZSBVEQmSZ1TL1_3bnpHC_4D_uGXwpUN-D-2oL4v0Zi2RhctvWb4AgAkc0YhCgR3ZDSkMuC-wTZhsKF9rBsr2r-eZOQfu3NOVC-TP4cOIItukWJwy8loph&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJbGvtsxdocIcOOGrBgCfuotfWj2y4ohYhAz5vlUzoUksFQV8xZPWeXndGhwoZbKvcTfkMQBbvTcTjegfCpSkNZeprl5nIi3bYgy4s4yXRisBD7mD3OVe1VlGmfw4LFswV4oCHMZe2FjmPC02zc8qz3bVEwJbuHgZj-EjA8FXw-8aavF&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJaPoodRn11hT-y9wrQDOOuUGuRsenkRcBNClwBHq_CaKv2VD7ihMNWwmL39haRljf_KXojr2eHbtfPCgI_RwQ2LKKTXHjyjTi79wNM9k4lm-i3hMnbMFjYtUWHKgW_jDMQ8xg2CwQO79Hxm-6aWnZ8to0ezXhSpBj2jw09pjEUz8zuP&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJZ3fJnRrO_Ocn3qY34sN9W-jVL1Ryuz7w-_TpuHd7gD0c7pWWd8j9sAGK5U6OFigtgblROts6FrfdfmAo4vnbVwSuPIGzR_EEyWGU546rhy6F3qIsw1FeSKhBD8lxtJuFEXFYLXHZTCgFQAIpMFnbLv3R3DAvpwB3hK8lrYVsDvvjgK&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJZq_Ixk-eZH1rpA7JL8SnIY8jBXJOkJocr_tz9kk2bjD02fJkkqYU447mphEzbVCD-ZN_mMZbhS-vYPoiIVAdDbxHI_6Q6UatYHHcpfEbjfCnw4LcqevnvDGERCC1-7pIO-ZOIySiDWCr2wO7YojcK6idpvewIyIeNbi6dr-GtdMiT3&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJaH3FuQELL_MYne6xvsiVtB0SRQXLIVjHFIiI4NfcCJTPaex_0XK6VXjoxMHowCbXxJMpNmxs790ktWFm8Ki1aIPexAvX1Ivdo0vTIFaqlhRxfXNMuBmgYfFDnD9JsQ19lLnR0oWtngUFQ7AS-FxcvV_haJLzERm0kbIzFrZOQlR-Qm&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJbSuWN0qIykEweVCRICVz2ATDeL8k9zuu0IOJiKb8Yait6WoUbu8weoXXmMcWN0E3Z8MEuMZXgheqA5D066yNgfPHIMCWEe05m4I_MkAlXncLtaKQb832fTvDnj49rCq1wYIHX99kTnJePkGLsKPSAvphL7BMeCx3uZpS8UVTecQ27o&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJazhovEmt_-Km4-YGeeg1j7X5kVisc_YoZPrfVEWjYcPhTfiDh4bnuS3Mzg1yDksX0c4lF7nYISpj1ANZpB8hi_xGTSK1AYgL3JCT4ajgSD-bXJh98JPU-g3RMXzqtCIayXT7EKQDH_Eifl32wHeNvizeyUq1-6MSeYLVNysHKfSAqr&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I",
        "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=ATplDJbe1SzFRgkDLwG5i0jFzLqLyISWCwBtmmCRLtvygMwq0EsU4f146cfZqnQxwd0RHBwSNwI5uEmcOzUGZsrR-954YKn3n2NAszzMwRsW_Rv1Qv0fpDDCpynWyYZOrZolPI4MghwvxRcCHxqnZkHNdzga-E8Ek9SibZPJVONJc0VWGflT&key=AIzaSyAjMUnmf2I4wMe-FGtzvn6xoEI2bYYuH6I"
      ]
    }, 
    weather : {
      "latitude": 41.56,
      "longitude": 14.66,
      "generationtime_ms": 0.16295909881591797,
      "utc_offset_seconds": 0,
      "timezone": "GMT",
      "timezone_abbreviation": "GMT",
      "elevation": 700.0,
      "current_units": {
        "time": "iso8601",
        "interval": "seconds",
        "temperature_2m": "°C",
        "is_day": "",
        "precipitation": "mm",
        "rain": "mm",
        "showers": "mm",
        "weather_code": "wmo code",
        "cloud_cover": "%",
        "wind_speed_10m": "km/h",
        "wind_direction_10m": "°"
      },
      "current": {
        "time": "2024-04-09T08:45",
        "interval": 900,
        "temperature_2m": 20.6,
        "is_day": 1,
        "precipitation": 0.0,
        "rain": 0.0,
        "showers": 0.0,
        "weather_code": 3,
        "cloud_cover": 83,
        "wind_speed_10m": 5.1,
        "wind_direction_10m": 231
      }
    },
    // THERE BUT THE DATA IS UNCORRECT, TO BE USED AFTER THE WIZARD AND BASWED ON THE WIZARD DATA
    ideas: {
      "touristInfo": {
        "interests": [
          {
            "type": "trekking"
          },
          {
            "type": "musica"
          },
          {
            "type": "restaurant"
          }
        ],
        "knowledge_about_location": "low",
        "who_travels": 3,
        "budgetRange": 1500,
        "travelDates": {
          "arrivalDate": "15-03-2024",
          "arrivalPartOfDay": "Sera",
          "departureDate": "17-03-2024"
        }
      },
      "tripIdeas": {
        "dailyPlan": [
          {
            "date": "16-03-2024",
            "activities": [
              {
                "type": "trekking",
                "name": "Sentiero del Matese",
                "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
                "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
                "duration": "Not available",
                "id": "f20c1d31-0370-4d70-b2be-89baa0a25e53"
              },
              {
                "type": "musica",
                "name": "Jazz Club Campobasso",
                "location": "Via Roma, 25, 86100 Campobasso (CB)",
                "placeId": "ChIJXSkLgpR1OhMRwfk3Qum0Ocg",
                "duration": "Not available",
                "id": "b10ea6fd-26ef-453b-a33e-d720088634a5"
              },
              {
                "type": "dining",
                "name": "Trattoria Da Nonno Cesare",
                "location": "Via Larino, 34, 86100 Campobasso (CB)",
                "placeId": "ChIJb8dBMS0LOhMRzj7F0HIsZZs",
                "duration": "Not available",
                "id": "159e1af7-b1ea-4c36-8143-8b9e0afc587f"
              }
            ],
            "accommodation": {
              "type": "hotel",
              "name": "Hotel San Giorgio, Campobasso, Italia",
              "location": "Via Insorti d'Ungheria, 86100 Campobasso (CB), Italia",
              "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
              "duration": "Not available",
              "id": "51633080-9095-4632-bfed-a102d7e61257"
            }
          },
          {
            "date": "17-03-2024",
            "activities": [
              {
                "type": "trekking",
                "name": "Riserva di Campolattaro",
                "location": "86020 Campolattaro (CB)",
                "placeId": "ChIJX6m0st4VOhMRFO1rjxRa2Qo",
                "duration": "Not available",
                "id": "8599a6e7-869e-4d20-af08-094e864fa7dc"
              },
              {
                "type": "musica",
                "name": "Teatro Savoia",
                "location": "Corso Vittorio Emanuele II, 86100 Campobasso (CB)",
                "placeId": "ChIJbeZUJC0LOhMRa-dJZdTvOaY",
                "duration": "Not available",
                "id": "64bedba2-bc48-43c0-8fd5-d7ae50381fe1"
              },
              {
                "type": "dining",
                "name": "Osteria del Borgo",
                "location": "Piazza Pepe, 2, 86100 Campobasso (CB)",
                "placeId": "ChIJwy0x4DmxMBMRUcO2cP2A61Y",
                "duration": "Not available",
                "id": "cf904d52-826b-4a29-bb12-6bee0ec1d23d"
              }
            ],
            "accommodation": {
              "type": "hotel",
              "name": "Grand Hotel Rinascimento, Campobasso, Italia",
              "location": "Via Labanca, 86100 Campobasso (CB), Italia",
              "placeId": "ChIJTYM0s9sKOhMRXF3XOCDYoXA",
              "duration": "Not available",
              "id": "0ac0761e-80ca-4721-8bbd-3350fc602975"
            }
          }
        ]
      },
      "response": "Ciao Marta! Ho preparato un itinerario emozionante per il tuo viaggio a Campobasso dal 15 al 17 marzo 2024. Il tuo viaggio inizierà con una serata di riposo presso l'Hotel San Giorgio. Il giorno successivo, ti aspettano avventure come il trekking nel Sentiero del Matese, musica dal vivo al Jazz Club Campobasso e una cena tradizionale alla Trattoria Da Nonno Cesare. Il tuo ultimo giorno sarà altrettanto speciale con trekking nella Riserva di Campolattaro, uno spettacolo al Teatro Savoia e una cena all'Osteria del Borgo, prima di riposarti al Grand Hotel Rinascimento. Questo itinerario è stato creato pensando ai tuoi interessi e al budget di 1500 euro per 3 persone. Spero che tu e i tuoi compagni di viaggio vi divertiate a esplorare Campobasso!"
   },
   // OK
   placeIDesc: {
      "transformed_query": "Il Parco Regionale del Matese, situato nella Provincia di Caserta, Italia, è un'area naturale incontaminata caratterizzata da tre laghi e vaste foreste. Questo parco è l'habitat naturale di specie protette come aquile reali e lupi, rendendolo un luogo ideale per gli amanti della natura e della fauna selvatica. Con un punteggio di 4.5, il parco offre un'esperienza unica ai suoi visitatori, essendo aperto 24 ore su 24, tutti i giorni della settimana, dal 9 aprile 2024 al 15 aprile 2024. Per ulteriori informazioni, è possibile contattare il parco al numero 0823 786942. Il Parco Regionale del Matese rappresenta una destinazione imperdibile per chi cerca un'esperienza turistica all'insegna della natura e dell'avventura, essendo classificato come attrazione turistica, zoo, parco, punto di interesse e stabilimento."
   },
   itinerary: {
      "touristInfo": {
        "interests": [
          {
            "type": "trekking"
          },
          {
            "type": "musica"
          },
          {
            "type": "restaurant"
          }
        ],
        "knowledge_about_location": "low",
        "who_travels": 3,
        "budgetRange": 1500,
        "travelDates": {
          "arrivalDate": "15-03-2024",
          "arrivalPartOfDay": "Sera",
          "departureDate": "17-03-2024"
        }
      },
      "tripIdeas": {
        "dailyPlan": [
          {
            "date": "15-03-2024",
            "activities": [],
            "accommodation": {
              "type": "hotel",
              "name": "Hotel San Giorgio, Campobasso, Italia",
              "location": "Via Insorti d'Ungheria, 86100 Campobasso, Italia",
              "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
              "duration": "Not available",
              "id": "6e7becfe-161e-4b12-9c90-fac619203779"
            }
          },
          {
            "date": "16-03-2024",
            "activities": [
              {
                "type": "trekking",
                "name": "Matese Mountain Adventure",
                "location": "Parco del Matese, Campobasso, Italia",
                "placeId": "ChIJtcYarXRhOhMRehOvUXO8ufM",
                "duration": "Not available",
                "id": "8ef25a89-9992-4635-be8a-cc300c882847"
              },
              {
                "type": "musica",
                "name": "Jazz Club Campobasso",
                "location": "Via Jazz, 12 86100 Campobasso (CB)",
                "placeId": "ChIJb77XoM10OhMRf0bFF_CAmxI",
                "duration": "Not available",
                "id": "fb284eba-e5e6-43c6-a45f-9b148b919d57"
              },
              {
                "type": "dining",
                "name": "Trattoria Da Nonna Sceppa",
                "location": "Via Roma, 45 86100 Campobasso (CB)",
                "placeId": "ChIJFUwKXC4LOhMR-JZhuqF7N_A",
                "duration": "Not available",
                "id": "12f3b643-901e-4664-ac04-80a5206cd04c"
              }
            ],
            "accommodation": {
              "type": "hotel",
              "name": "Hotel San Giorgio, Campobasso, Italia",
              "location": "Via Insorti d'Ungheria, 86100 Campobasso, Italia",
              "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
              "duration": "Not available",
              "id": "35800d63-bc6d-4da0-b1d0-40acfd4cbc78"
            }
          },
          {
            "date": "17-03-2024",
            "activities": [],
            "accommodation": {
              "type": "hotel",
              "name": "Hotel San Giorgio, Campobasso, Italia",
              "location": "Via Insorti d'Ungheria, 86100 Campobasso, Italia",
              "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
              "duration": "Not available",
              "id": "7b46fc5f-dd16-4084-ad55-9305ef967c64"
            }
          }
        ]
      },
      "response": "Ciao Marta! Ho preparato per te e i tuoi compagni di viaggio un itinerario emozionante per esplorare Campobasso dal 15 al 17 marzo 2024. Arriverete di sera e alloggerete all'Hotel San Giorgio, situato nel cuore della città. Il 16 marzo, vi aspetta una giornata ricca: inizierete con un'avventura di trekking sul Monte Matese, seguita da una serata di musica jazz al Jazz Club Campobasso. Concluderete la giornata con una cena tipica molisana alla Trattoria Da Nonna Sceppa. Il 17 sarà il giorno della partenza, spero che questa esperienza vi lasci ricordi indimenticabili di Campobasso!"
    }
 }


 export const mockReq = {
  approved : [
    {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
    },
    {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
    },
    {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
    },
    {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
    },
  ], 
  "not-approved": [
    {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "duration": "Not available",
      "id": "f20c1d31-0370-4d70-b2be-89baa0a25e53"
    },              {
      "type": "trekking",
      "name": "Sentiero del Matese",
      "location": "Parco Regionale del Matese, 86090 Campobasso (CB)",
      "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
      "duration": "Not available",
      "id": "f20c1d31-0370-4d70-b2be-89baa0a25e53"
    },
  ]
 }



 export const updatedI = {
  "touristInfo": {
    "interests": [
      {
        "type": "museum"
      },
      {
        "type": "restaurant"
      },
      {
        "type": "musica"
      },
      {
        "type": "trekking"
      },
      {
        "type": "vino"
      },
      {
        "type": "spiagge"
      },
      {
        "type": "religione"
      },
      {
        "type": "misteri"
      }
    ],
    "knowledge_about_location": "Si",
    "who_travels": 2,
    "budgetRange": 2030,
    "travelDates": {
      "arrivalDate": "24-4-2024",
      "arrivalPartOfDay": "Mattina",
      "departureDate": "26-4-2024"
    }
  },
  "tripIdeas": {
    "dailyPlan": [
      {
        "date": "24-4-2024",
        "activities": [
          {
            "type": "museum",
            "name": "Museo dei Misteri",
            "location": "Via dei Misteri, 10, 86100 Campobasso (CB)",
            "placeId": "ChIJN0vEcc10OhMRnWe89VIvELg",
            "lat": "41.5585976",
            "lng": "14.6564651",
            "duration": "Not available",
            "id": "787e0b3e-2505-4a51-815a-06e5d1392a96"
          },
          {
            "type": "dining",
            "name": "Trattoria Da Nonna",
            "location": "Via Roma, 45, 86100 Campobasso (CB)",
            "placeId": "ChIJFUwKXC4LOhMR-JZhuqF7N_A",
            "lat": "41.5591334",
            "lng": "14.658043",
            "duration": "Not available",
            "id": "1a30bf6d-52b3-4841-bdd6-04d14b3d0789"
          }
        ],
        "accommodation": {
          "type": "hotel",
          "name": "Hotel San Giorgio, Campobasso, Italia",
          "location": "Via Insorti d'Ungheria, 86100 Campobasso (CB), Italia",
          "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
          "lat": "41.5625614",
          "lng": "14.6657423",
          "duration": "Not available",
          "id": "3c9657d8-4c62-46d8-8df9-b58ca768d9f7"
        }
      },
      {
        "date": "25-4-2024",
        "activities": [
          {
            "type": "trekking",
            "name": "Sentieri del Matese",
            "location": "Parco Regionale del Matese, 86100 Campobasso (CB)",
            "placeId": "ChIJvev1V8RmOhMRkDBLkiDhCQ8",
            "lat": "41.5602674",
            "lng": "14.6617334",
            "duration": "Not available",
            "id": "055cfd4c-f15b-4aed-b8de-d8ccdfcff1e1"
          },
          {
            "type": "vino",
            "name": "Cantina Biferno",
            "location": "Via Vino, 12, 86100 Campobasso (CB)",
            "placeId": "ChIJJyR_vMp0OhMRgDqZLzapXE0",
            "lat": "41.5602674",
            "lng": "14.6617334",
            "duration": "Not available",
            "id": "374a333c-9fad-4a06-a9fe-a4ad7641d1c9"
          }
        ],
        "accommodation": {
          "type": "hotel",
          "name": "Hotel San Giorgio, Campobasso, Italia",
          "location": "Via Insorti d'Ungheria, 86100 Campobasso (CB), Italia",
          "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
          "lat": "41.5625614",
          "lng": "14.6657423",
          "duration": "Not available",
          "id": "3bf61fa0-9863-4827-b057-f07e5361d662"
        }
      },
      {
        "date": "26-4-2024",
        "activities": [
          {
            "type": "spiagge",
            "name": "Spiaggia di Termoli",
            "location": "Lungomare Cristoforo Colombo, 86039 Termoli (CB)",
            "placeId": "ChIJ7RUI_bjNMBMRUn6Ksiofwts",
            "lat": "42.0069499",
            "lng": "14.9745502",
            "duration": "Not available",
            "id": "49ab876f-730f-4045-9b21-1970e5fa39ad"
          },
          {
            "type": "religione",
            "name": "Cattedrale di Santa Maria Maggiore di Campobasso",
            "location": "Piazza Prefettura, 86100 Campobasso (CB)",
            "placeId": "ChIJ7Zl_dy4LOhMRSoOYE-8wRQk",
            "lat": "41.5604811",
            "lng": "14.6584689",
            "duration": "Not available",
            "id": "e2b1b920-83b6-4a48-97be-316eab33ab7b"
          }
        ],
        "accommodation": {
          "type": "hotel",
          "name": "Hotel San Giorgio, Campobasso, Italia",
          "location": "Via Insorti d'Ungheria, 86100 Campobasso (CB), Italia",
          "placeId": "ChIJQ3Jt9i4LOhMR4H4oiedI3Ow",
          "lat": "41.5625614",
          "lng": "14.6657423",
          "duration": "Not available",
          "id": "ee10a0df-9317-438c-b324-0c7062df9cc8"
        }
      }
    ]
  },
  "response": "Ciao Filippo! 🌞 Ho preparato per te e il tuo compagno di viaggio un itinerario che vi farà scoprire le meraviglie di Campobasso e dintorni, dal 24 al 26 aprile 2024. Vi immergerete nella cultura locale visitando il Museo dei Misteri, assaporando i piatti tipici alla Trattoria Da Nonna, esplorando i Sentieri del Matese e degustando il vino alla Cantina Biferno. Non mancherà un tuffo nella storia con la Cattedrale di Santa Maria Maggiore e un relax sulla Spiaggia di Termoli. Vi auguro un viaggio indimenticabile pieno di scoperte e momenti felici! 🍇🏖️🍽️"
}