import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
import {chatMock} from '../utils/mocks';
import { useLS } from '../contexts/LsContext.js';

  
  export const chatContext = createContext();
  
  const useProvideChat = () => {
    const [chatList, setChatList] = useState(chatMock);

    const LSData = useLS() || {};
    const {setToLS, isInLS} = LSData;

    const updateChat = async (payload) => {
      const copyOfChatList = [...chatList];
      copyOfChatList.push(payload)
      setChatList(copyOfChatList);
      await setToLS('chatLS', copyOfChatList)
    }

    useEffect(() => {
      const setChatOnLoad = async () => {
        const chatInLs = await isInLS('chatLS');
        if (chatInLs) {
          const formattedChat = await JSON.parse(chatInLs);
          setChatList(formattedChat)
          return
        }
      }
      setChatOnLoad();
    }, [])

    return {
        chatList,
        updateChat
    };
  };
  
  export const ChatProvider = ({ children }) => {
    const chat = useProvideChat();
    return (
      <chatContext.Provider value={chat}>{children}</chatContext.Provider>
    );
  };
  
  export const useChat = () => useContext(chatContext);
  