import React from 'react'
import logoCB from '../assets/images/loghi.svg';
import logoZIA from '../assets/images/logo.svg';
import hamburger from '../assets/images/hamburger.svg';
import close from '../assets/images/close.svg';
import { useLayout } from '../contexts/layoutContext.js';
import Menu from './Menu.js';


const Header = () => {

    const layoutData = useLayout() || {};
    const {showMenu, toggleMenu} = layoutData;

    return (
        <>
            <header className='w-full h-[64px] flex items-center text-center justify-center shadow-md fixed top-0 left-0 bg-white z-20 px-4'>
                <div className='logoCB w-[65%]'>
                    <img className='block mr-auto' src={logoCB} />
                </div>
                <div className='logoZIA w-[35%] flex items-center'>
                    <img className='block mx-auto h-[40px]' src={logoZIA} />
                    <button onClick={toggleMenu} className='float-right block menu'>
                        <img className='block ml-auto h-[18px]' src={!showMenu ? hamburger : close} />
                    </button>
                </div>
            </header>
            <Menu toggleMenu={toggleMenu} showMenu={showMenu}/>
        </> 
    )
}

export default Header