import React, { useState, useEffect } from "react";
import { useChat } from "../contexts/chatContext";
import clsx from "clsx";
import Close from "./icons/iconsClose";
import SingleChat from "./singleChat";

const ChatView = ({ show, setShow, setInputValue, inputValue, handleSubmit, inputRef, endOfContainerRef }) => {
    const chatData = useChat() || {};
    const { chatList } = chatData;
    const [modalHeight, setModalHeight] = useState(null);

    const scrollToEndOfChat = () => {
        const endOfChat = document.getElementById('endOfChat');
        endOfChat.scrollIntoView({
            behavior: "smooth",
        });
    }

    useEffect(() => {
        function isKeyboardOpen() {
            const { innerHeight, innerWidth } = window;
            const vh = Math.min(innerHeight, innerWidth);
            // Assuming keyboard height threshold as 100px (adjust as needed)
            return vh < innerHeight;
        }

        function calculateModalHeight() {
            if (show && isKeyboardOpen()) {
                const { innerHeight } = window;
                // Assuming you have a fixed header of height 90px
                const fixedHeaderHeight = 64;
                const availableHeight = innerHeight - fixedHeaderHeight;
                setModalHeight(availableHeight);
            } else {
                setModalHeight(null);
            }
        }

        // Call the function when show or window size changes
        setInputValue('')
        calculateModalHeight();
        window.addEventListener('resize', calculateModalHeight);
        return () => window.removeEventListener('resize', calculateModalHeight);
    }, [show]);

    useEffect(() => {
        scrollToEndOfChat();
    }, [chatList?.length])

    return (
        <div
            className={clsx("overflow-scroll chatView w-full shadow-lg bg-light-grey fixed right-0 transition-all z-30", !show ? 'top-[100vh]' : 'top-[63px]')}
            style={{height: modalHeight ? modalHeight : 'auto'}}
        >
            {show && (
                <button onClick={() => setShow(false)} className="block p-3 fixed top-[85px] right-[15px] shadow-md h-10 w-10 rounded-full flex items-center bg-white">
                    <Close className="cursor-pointer" fill="fill-orange" />
                </button>
            )}
            <div className="pt-16 pb-32">
                {chatList.map((el, index) => {
                    return <SingleChat key={index} agent={el?.agent} text={el?.text} options={el?.options} />
                })}
            </div>
            {show && (    
                <div className="w-full px-4 fixed bottom-10 rounded-full z-30">
                    <div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <input onFocus={scrollToEndOfChat} ref={inputRef} value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="rounded-full px-2 h-12 px-4 w-full shadow-lg" type="text" />
                        </form>
                    </div>
                </div>
            )}
            <div ref={endOfContainerRef} id="endOfChat"></div>
        </div>
    )
}

export default ChatView